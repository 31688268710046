@import '@app/styles/variables';

.outerLayer {
  overflow: auto;
  max-height: 80vh;
}

.outerLayer::-webkit-scrollbar {
  width: 3px;
}

.outerLayer::-webkit-scrollbar-thumb {
  background-color: $grey;
  border-radius: 4px;
}

.outerLayer::-webkit-scrollbar-track {
  background-color: transparent;
}

.loaderWrapper {
  display: flex;
  padding: 30px;
  justify-content: center;
}

.wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 14px;
  flex-direction: column;
}


.link {
  width: 100%;
}

.btn {
  width: 100%;
}
