@import '../../../styles/variables';

.balanceTitle {
  font-family: Playfair, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  color: #d9d9d9;
}

.boxBalance {
  margin-top: 10px;
  &_title {
    font-family: Playfair, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    margin-top: 31px;
  }
  &_span {
    font-family: Playfair, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  }
  &_TextField {
    background: #111b35;
    border-radius: 10px;
  }
}

.btn{
  flex: 1;
  padding: 9px;
  margin-bottom: 10px;
  color: black;
  font-size: 16px;
}
