@import '../../../styles/variables';

.container{
  display: flex;
  flex-direction: column;
  padding: 30px 12px 0;

  &__header{
    display: flex;
    margin-bottom: 30px;
    gap: 16px;
  }

  &__text{
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.icon{
  position: relative;
  width: 38px;
  height: 36px;
}

.btn{
  flex: 1;
  padding: 9px;
  margin-bottom: 10px;
  color: black;
  font-size: 15px;
}

.btnDisabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.modalText{
  font-family: Playfair, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  margin-top: 30px;
}

.modalInviterAddress{
  display: flex;
  margin-top: 30px;
  gap: 10px;
}

.input {
  width: 80%;
  background: #111B35;
  border-radius: 8px;
  min-width: 0;
  min-height: 48px;
  font-family: Playfair, sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  border: none;
}

.input:focus{
  outline: 1px solid #4DABF5;
  color: #4DABF5;
}

@media (max-width: $tabletMediumWidth) {
  .container {
    display: flex;
    width: 100%;
  }
}
