@import '../../../styles/variables';

.infoBlock {
  display: flex;
  flex-direction: column;
}

.infoContentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.infoContentTitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  color: $white;
  margin-left: 10px;
  margin-top: 13px;
}

.infoSubtitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  color: $orange_300;
  margin-left: 15px;
  cursor: pointer;
}

.infoSub {
  width: 100%;
}

.infoSubAvailable {
  margin-top: 5px;
  &_title {
    color: $white;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
  display: flex;
  align-items: flex-end;
}

.infoSubInvested {
  margin-top: 10px;
  &_title {
    color: $white;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  &_value {
    font-size: 16px;
    font-weight: 800;
    color: $orange_300;
    margin-left: 15px;
    cursor: pointer;
  }
  display: flex;
  align-items: flex-end;
}

.usdtEquivalent {
  color: $white;
  font-size: 10px;
  font-weight: 400;
  margin-top: 4px;
  text-align: left;
  &_value {
    color: $orange_300;
    font-size: 16px;
    font-weight: 400;
  }
  display: flex;
}

.divider {
  width: 100%;
  color: $orange_300 !important;
  opacity: 0.4;
}
