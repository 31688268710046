.note {
  text-align: left;
  display: flex;
  column-gap: 14px;
  align-items: center;
  font-family: Playfair, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.image {
  width: 20px;
  height: 20px;
}
