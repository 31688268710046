@import '../../../../styles/variables';

.modalInviterAddress {
  display: flex;
  margin-top: 30px;
  gap: 10px;
  position: relative;
}

.input {
  width: 80%;
  background: #012935;
  min-width: 0;
  min-height: 48px;
  border: none;
  font-family: Playfair, serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  padding-left: 15px;

  &:disabled {
	opacity: 0.5;
  }
}

.input:focus {
  outline: 1px solid $orange;
  color: $orange;
}

.btn {
  flex: 1;
  padding: 9px;
}

.modal {
  .clientAgreement {
	display: flex;
	margin-top: 12px;
	gap: 12px;
  }

  .mainContainer {
	display: flex;
	flex-direction: column;
  }
}

.clientAgreementText {
  cursor: pointer;
}

.helpInfo {
  margin-top: 20px;
}
