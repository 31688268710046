@import "../../../styles/variables";

.wrapper {
  padding: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $blue_300_op_006;
  display: flex;
  flex-direction: column;
}

@media (max-width: $tabletMediumWidth) {
  .wrapper {
    max-width: 100%;
  }
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: $white;
}

.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: $grey_050;
  margin-bottom: 5px;
}
