@import '@app/styles/variables';

.title {
  font-size: 1rem;
  line-height: 20px;
  font-weight: bold;
  color: $orange;
}

.title:not(:first-child) {
  margin-top: 10px;
}

.subtitle {
  font-size: 0.9rem;
}

.listWrapper {
  padding-left: 10px;
}
