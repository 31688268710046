@import '../../styles/variables';

.container {
  display: flex;
  background: $main-bg;
  height: 100vh;
  height: 100dvh;
  flex-basis: 80px;
  overflow: auto;
}

.containerMobile {
  z-index: 19;
  position: absolute;
  width: 100%;
  top: 40px;
}

.content {
  padding: 40px 40px;
  flex: 1;
  position: relative;
}

.infoBar {
  height: 90vh; //fix for macbook, safari
  overflow-y: auto;
  padding: 10px 40px 0 40px;

  position: sticky;
  top: 0;
  height: 100vh;
  height: 100dvh;
  overflow: auto;
}

.infoBarMobile{
  padding: 0px 20px;
}

.collapseWallet {
  padding: 110px 15px 15px 15px;
  position: relative;
  height: 100vh;
  top: 0;
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10;

  background: #012e3b;
}

.collapseWalletIOS {
  bottom: -40%;
}

.noticeTitle {
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

@media (max-width: $tabletMediumWidth) {
  .container {
    flex-direction: column;
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 110px 15px 60px;
  }
  .infoBar {
    display: none;
  }
}

@media (min-width: $tabletMediumWidth) {
  .collapseWallet {
    display: none;
  }
}
