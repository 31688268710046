@import '../../../styles/variables';

.modal {
  display: grid;
  grid-template-areas:
    'img title'
    'img description';
  grid-column-gap: 40px;

  &__img {
    width: 60px;
    height: 60px;
    grid-area: img;
    justify-self: center;
    align-self: center;
  }

  &__title {
    grid-area: title;
    margin-bottom: 10px;
  }

  &__description {
    color: $grey;
    grid-area: description;
    word-break: break-word;
    font-family: 'Playfair', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  &__helpLink {
    color: $primary;
  }
}
