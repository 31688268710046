@import "../../../styles/variables";

.componentWrapper {
  max-width: 270px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .languageButton {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: $tabletMediumWidth) {
  .componentWrapper {
    max-width: none;
  }
}


.container {
  display: flex;
  flex-direction: column;
  background-color: #012935;
}

.disconnectButton {
  margin: 10px 20px 20px 20px;
  padding: 8px;
}

.profile {
  padding: 12px;
  display: flex;
}

.profileImage {
  width: 48px;
  height: 48px;
  background: rgba(77, 171, 245, 0.06);
  border-radius: 50%;
}

.profileInfo {
  margin-left: 12px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profileName {
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.profileVerify {
  display: flex;
  align-items: center;
}

.typographyG {
  color: #5FEDCF;
  margin-left: 5px;
  font-size: 11px;
}

.typographyY {
  color: yellow;
  margin-left: 5px;
  font-size: 11px;
}

.typographyR {
  color: red;
  margin-left: 5px;
  font-size: 11px;
}

.verifyInfo {
  margin-left: 5px;
  font-weight: 800;
  font-size: 10px;
  letter-spacing: 0.02em;
  color: #5fedcf;
}

.settings {
  position: relative;
  margin-top: auto;
  width: 12px;
  height: 12px;
}

.settings:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.4s;
}

.settingsIcon {
  position: relative;
  width: inherit;
  height: inherit;
}

.wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #013342;
  border-radius: 16px 16px 0 0;
}

.select {
  padding: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  cursor: pointer;
  background: rgba(77, 171, 245, 0.06);
  align-items: center;
}

.selectCircle {
  width: 24px;
  height: 24px;
  background: linear-gradient(347.67deg, #4dabf5 17.93%, #5fedcf 82.07%);
  box-sizing: border-box;
  border-radius: 50%;
}

.selectContent {
  margin: auto 15px;
  display: flex;
  flex-direction: column;
}

.selectTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
}

.selectSubTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.64);
}

.noticeTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #000;
}

.noticeSubTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #000;
  line-height: 1.4;
}

.circularMargin {
  margin: 0 auto;
}

.infoBlock {
  border-radius: 8px;
}

.infoTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.infoContent {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.infoContentItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.infoContentTitleWrapper {
  display: flex;
  align-items: center;
}

.infoContentCircle {
  position: relative;
  width: 12px;
  height: 12px;
  background: #a0a0a0;
  border: 1px solid #818181;
  border-radius: 50%;
}

.infoContentTitle {
  margin-left: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  background: linear-gradient(347.67deg, #4dabf5 17.93%, #5fedcf 82.07%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.infoSubtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #fff;
  word-break: break-word;
}

.infoWrapper {
  padding: 20px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  background: rgba(77, 171, 245, 0.06);
  border-radius: 8px;
}

.subTitle {
  margin-top: 6px;
  font-size: 10px;
  color: $white-lightened;
}

.image {
  position: relative;
  width: inherit;
  height: inherit;
}

@media (max-width: $tabletMediumWidth) {
  .container {
    background-color: transparent;
    padding-bottom: 70px;
  }

  .profile {
    margin-bottom: 12px;
    padding: 24px;
    border: 1px solid rgba(77, 171, 245, 0.12);
    border-radius: 8px;
  }

  .settingsIcon {
    margin-top: 5px;
    width: 20px;
    height: 20px;
  }

  .infoIcon {
    width: 32px;
  }

  .wrapper {
    padding: 0;
    background: transparent;
  }
}

.modal {
  // temporary design
  padding: 30px;
  background: white;

  a {
    color: blue;
  }
}

.copyright {
  color: #aaa;
  bottom: 10px;

  p {
    font-size: 12px;
  }
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
