.backIcon {
  cursor: pointer;
  position: absolute;
  top: 30px;
  width: 14px;
  height: 14px;
  left: 29px;
}

.agreement{
  margin-top: 15px;
  color: #fff;
}
