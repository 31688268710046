.outerLayerFullSize {
  overflow: auto;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.wrapper {
  width: 100%;
  position: relative;
  min-height: 300px;
}

.outerLayerFullSize .wrapper {
  width: 100%;
  height: 100%;
  background: #012e3b;
}

.btn {
  position: absolute;
  bottom: -25px;
  left: calc(50% - 25px);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: white;
}

.outerLayerFullSize .btn {
  bottom: 0;
}
