@import '../../../../styles/variables';

.modal {
  display: flex;
  flex-direction: column;
  padding: 38px;

  &__title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &_span{
      font-size: 13px;
      margin-left: 10px;
      color: yellow;
    }
  }

  &__description {
    color: $grey;
    grid-area: description;
    word-break: break-word;
    font-family: 'Playfair', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  &__btn {
    width: 100%;
    padding: 15px 0;
    margin-top: 30px;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
  }
}
