@import '../../styles/variables';

.modal {
  background-color: rgba(11, 20, 41, 0.6);
  backdrop-filter: blur(10px);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.closeBtn {
  position: absolute;
  right: 29px;
  top: 30px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.content {
  position: relative;
  background: #012e3b;
  padding: 10px;
  width: 50%;
  max-width: 520px;
  border-radius: 16px;
}

//custom content styles
.permissionContent {
  width: 80%;
  max-width: 80%;
}

.closed {
  display: none;
}

@media (max-width: $maxMobileWidth) {
  .content {
    padding: 40px;
  }
}

@media (max-width: $tabletMediumWidth) {
  .content {
    width: 70%;
  }
}

@media (max-width: $maxMobileWidth) {
  .content {
    width: 90%;
  }
}
