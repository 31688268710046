.with-scroll::-webkit-scrollbar {
  height: 3px; //for horizontal
  width: 3px; //for vertical
}

.with-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.with-scroll::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 2px;
}
