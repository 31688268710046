.wrapper {
  overflow-y: auto;
  max-height: 500px;
  color: white;
}

.wrapper_padding {
  padding: 20px 20px 20px 40px;
}


.agreementDate {
  margin: 50px 0;
}

.section {
  margin-bottom: 30px;

  h2 {
    padding-bottom: 10px;
  }

  ol {
    margin-left: 25px;
  }

  ul {
    margin-left: 45px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }


  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }

  li .ol > li {
    margin: 0;
    margin-top: 10px;
  }

  li .ol > li:before {
    content: counters(item, '.') ' ';
  }
}

.nestedOl {
  font-weight: normal;
}
