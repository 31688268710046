.main {
  display: flex;
  flex-direction: column;
}

.checkBoxBlock {
  display: flex;
  flex-direction: row;
  gap: 5px;

  a {
    text-decoration: underline;
  }
}

.button {
  margin-top: 20px;
  padding: 8px;
  width: 100%;
}

.boxError {
  height: 50px;
}

.errorInfo {
  font-family: Playfair, sans-serif;
  font-size: 12px;
  color: orangered;
  margin: 5px 0;
}

.partnerAgreementText{
  cursor: pointer;
}
