@import '../../../styles/variables';

.modal {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__img {
    width: 90px;
    height: 90px;
    grid-area: img;
    justify-self: center;
    align-self: center;
  }

  &__finish {
    flex-direction: row !important;
  }

  &__title {
    grid-area: title;
  }

  &__title_finish {
    display: flex;
    align-items: center;
  }

  &__description {
    color: $grey;
    grid-area: description;
    word-break: break-word;
    font-family: 'Playfair', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  &__helpLink {
    color: $primary;
  }
}
