@import '@app/styles/variables';

.outerLayer {
  overflow: auto;
  max-height: 80vh;
  padding: 0 5px;
}

.outerLayer::-webkit-scrollbar {
  width: 3px;
}

.outerLayer::-webkit-scrollbar-thumb {
  background-color: $grey;
  border-radius: 4px;
}

.outerLayer::-webkit-scrollbar-track {
  background-color: transparent;
}

.loaderWrapper {
  display: flex;
  padding: 30px;
  justify-content: center;
}

.wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 14px;
  flex-direction: column;
}

.buttonWrapper{
  display: flex;
  justify-content: flex-end;
  gap:10px
}
