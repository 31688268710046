.container {
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

.countdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;

  p {
    font-size: 40px;
    width: 52px;
  }
  span {
    font-size: 16px;
  }
}

.title {
  font-size: 40px;
  margin-top: 20px;
}
