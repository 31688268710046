@import 'ui/index';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: Playfair, sans-serif;
}

a {
  font-family: Playfair, sans-serif;
  color: #FBBE68;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* GLOBAL FONTS STYLES */
h2 {
  font-family: Playfair, sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0em;
  color: #fff;
}
h3 {
  font-family: Playfair, sans-serif;
  font-size: 18px;
  font-weight: 800 !important;
  line-height: 21px;
  letter-spacing: 0em;
  color: #fff;
}

h4 {
  font-weight: 800 !important;
}

.light {
  color: #ffffffa3;
}
.white {
  color: #ffffff;
}
.primary {
  color: #fbbe68;
}
.red {
  color: #f64785;
}
.light-blue {
  color: #4dabf5a3;
}
.text-regular-10 {
  font-family: Playfair, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
}
.text-regular-12 {
  font-family: Playfair, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}
.text-regular-14 {
  font-family: Playfair, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.text-light-10 {
  font-family: Playfair, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
}
.text-light-12 {
  font-family: Playfair, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
}
.text-bold-12 {
  font-family: Playfair, sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0em;
}
.text-bold-14 {
  font-family: Playfair, sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
}
/* !GLOBAL FONTS STYLES! */
/* BLOCK STYLES */
.block {
  display: flex;
  border-radius: 16px;
  order: 1;
}
.block_variant_filled {
  background: #012E3B;
}
.block_optional {
  display: flex;
  border-radius: 16px;
  top: -20px;
  position: relative;
  order: 2;
}
.block_variant_filled_optional {
  background: #013746;
;
}
.block_variant_outlined {
  border: 1px solid rgba(77, 171, 245, 0.12);
}
/* !BLOCK STYLES! */
/* SELECT BUTTON */
.selectBtn_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}
.selectBtn_item {
  flex: 1;
  color: #4dabf5;
  border: 2px solid rgba(77, 171, 245, 0.24);
  border-radius: 6px;
  padding: 8px 18px;
  text-align: center;
}
.selectBtn_selected {
  background: #4dabf5;
  color: #131f3d;
}
/* !SELECT BUTTON! */
/* LIST STYLES */
.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px;
  gap: 40px;
}
.list_column {
  flex: 1;
  width: 100%;
}
.list_item {
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-width: 300px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  gap: 10px;
}
.list_item:not(:last-child) {
  border-bottom: 1px solid #4dabf51f;
}
.list_item:not(:first-child) {
  margin-top: 16px;
  white-space: nowrap;
}
.list_item:last-child {
  padding-bottom: 0;
}
.list_item > p:last-child {
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.border_bottom {
  padding-bottom: 16px !important;
  border-bottom: 1px solid #4dabf51f;
}

.break-word {
  word-break: break-word;
}

.block_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 34px;
}

/* чтобы снэкбары были видны поверх модалок на весь экран*/
.SnackbarContainer-root {
  z-index: 20000 !important;
}

@media (max-width: 1200px) {
  .list {
    flex-direction: column;
    gap: 16px;
  }
  .list_item {
    min-width: auto;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px solid #4dabf51f;
  }
  .list_item:last-child {
    padding-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .list {
    padding: 12px;
    padding: 24px 12px;
  }
  .list_item {
    padding-left: 12px;
    padding-right: 12px;
    min-width: auto;
  }
}
/* LIST STYLES! */
/* STATUS STYLES */
.Confirmed,
.Staked,
.Active {
  color: #5fedcf !important;
}

.Pending {
  color: #ffab52 !important;
}

.Expired {
  color: #f64785 !important;
}

.Rejected {
  color: #ffffffa3 !important;
}

.Unstaked {
  color: #4dabf5 !important;
}
/* !STATUS STYLES! */

/* Wallet Connect Modal QR code window*/
.walletconnect-modal__footer {
  display: none !important;
}

/* 04.10.22 4-й элемент - ссылка на Метамаск*/
.walletconnect-connect__button__icon_anchor:not(:nth-child(4)) {
  display: none;
}

.walletconnect-connect__buttons__wrapper__wrap {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.css-12rsbf4-MuiSlider-valueLabel:before {
  content: none;
}


.font-days-one {
  font-family: Playfair, sans-serif;
}

body {
  background: #012E3B url('/assets/sca_background.png') center center
    no-repeat;
  background-size: cover;
}

html,
body,
#__next {
  min-height: 100%;
}
div[role="presentation"].MuiAutocomplete-popper {
  z-index: 1000000;
}

.blocked-field {
  .css-tj5g16-MuiInputBase-root-MuiOutlinedInput-root .Mui-disabled {
    color: #FFFFFFA3 !important;
    -webkit-text-fill-color: #FFFFFFA3 !important;

    &::placeholder {
      color: #FFFFFFA3 !important;
    }

    /* Vendor-specific placeholder styles */
    &::-webkit-input-placeholder {
      color: #FFFFFFA3 !important;
    }

    &:-moz-placeholder {
      color: #FFFFFFA3 !important;
      opacity: 1 !important;
    }

    &::-moz-placeholder {
      color: #FFFFFFA3 !important;
      opacity: 1 !important;
    }

    &:-ms-input-placeholder {
      color: #FFFFFFA3 !important;
    }

    &::-ms-input-placeholder {
      color: #FFFFFFA3 !important;
    }
  }
}

