$error: #FF4B4B;
$white: #FFFFFF;
$orange: #FFAB52;
//blue

//blue 300 opacity
$blue_300_op_006: #4DABF50F;
$blue_300_op_008: #4DABF514;
$blue_300_op_024: #4DABF53d;
$blue_300_op_064: #4DABF5A3;

//blue 050 - 200 deprecated, use blue_300_op palette!
$blue_050: $blue_300_op_006;
$blue_100: $blue_300_op_024;
$blue_200: $blue_300_op_064;
$blue_300: #4DABF5;
$blue_600: #162345;
$blue_700: #162744;
$blue_800: #131F3D;
$blue_900: #111b35;

$blue: $blue_300;

// orange
$orange_100: #FEAF3C3d;
$orange_200: #FEAF3CA3;
$orange_300: #FEAF3C;

$orange: $orange_300;

//green
$green_050: #5fedcf3d;
$green_100: #5FEDCFA3;
$green_200: #5FEDCF;
$green_300: #58D6DC;

$green: $green_200;
//pink
$pink_050: #F647853D;
$pink_300: #F64785;
$pink_400: #c6538c;

$pink: $pink_400;

//orange
$orange: #FFAB52;

//grey
$grey_050: #FFFFFFA3;
$grey_300: #ABB1BC;

$grey: $grey_300;
//to support old variables

//blue
$darkest-blue: $blue_800;
$darked-blue: $blue_900;
$dark-blue: $blue_600;
$very-dark-blue: $blue_700;
$blue-darkened: $blue_200;
$lightened-blue: $blue_100;
//todo: #285767, #172a4d
//green
$primary-darkened: $green_050;
$primary-hover: $green_100;
$primary: $green_200;
$soft-cyan: $green_300;
//pink
$pink-bright-darkened: $pink_050;
$pink-bright: $pink_300;
//grey
$white-lightened: $grey_050;
$grey: $grey_300;
//todo: #34425C,

//adaptive
$maxMobileWidth: 500px;
$tabletMediumWidth: 769px;
$tabletBigWidth: 996px;
$desktopMinWidth: 1100px;
$desktopMediumWidth: 1920px;
$desktopMaxWidth: 2000px;
$desktopSuperMaxWidth: 2500px;

//Mui adaptive breakpoints
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

$main-bg: transparent;
$secondary-bg: $blue_050;
