@import '@app/styles/_variables.scss';

.button {
  font-family: Playfair, sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  padding: 8px;
  border-radius: 6px;
}

.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.link {
  padding: 6px;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: $blue_800;
  background: $orange;
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
}

.filled {
  background: $orange;
  color: black;
  border: none;
  transition: all ease-in 0.3s;
  &:hover {
    background: $orange_200;
    cursor: pointer;
  }
}

.disabled {
  background: #FBBE68;
  color: black;
  border: none;
  opacity: 0.24;
}

.outlined {
  border: 2px solid $green_050;
  color: $green;
  background: transparent;
  transition: all ease-in 0.3s;
  &:hover {
    color: #fff;
    border: 2px solid $green;
    cursor: pointer;
  }
}
