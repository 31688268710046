.inputBlock {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: rgba(77, 171, 245, 0.08);
  border-radius: 16px;
  transition: all ease 0.3s;
}

.inputWrapper {
  padding: 14px 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-width: fit-content;
  align-items: center;
  background: #012935;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all ease 0.3s;
  position: relative;
}

.input {
  font-family: Playfair, sans-serif;
  flex-grow: 1;
  font-weight: 800;
  font-size: 24px;
  color: #fff;
  background: transparent;
  border: none;
  min-width: 60px;

  &:focus {
    outline: none;
  }
}

.tokenName {
  font-weight: 800;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.48);
  margin-right: 22px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 24px;
  font-weight: 800;
  font-size: 12px;
  line-height: 0;
  text-transform: uppercase;
  text-align: center;
  color: #131f3d;
  background: #FEAF3C;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
}

.available {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-top: 12px;
  color: #fff;

  &__title {
    margin-right: 12px;
  }
}

