@import '@app/styles/variables';

.content {
  background-color: #012E3B;
  border-radius: 16px;
  padding: 48px;
  width: 80%;
}

.btnClose {
  position: absolute;
  right: 48px;
  top: 48px;
  color: #9e9e9e;
}

.dialogText{
  color: #FFF;
}

@media (max-width: $maxMobileWidth) {
  .content {
    padding: 5px;
    width: 100%;
    margin: 10px;
  }

  .btnClose {
    right: 10px;
    top: 10px;
  }
}
