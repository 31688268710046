@import "../../../styles/variables";

.wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: $blue_300_op_006;
  border-radius: 16px;
  width: 100%;
}

.modalTitle {
  font-size: 1.1rem;
  line-height: 20px;
}

.modalSubtitle {
  font-size: 1rem;
}

.title {
  font-size: 16px;
  line-height: 20px;
}

.subtitle {
  color: $orange;
  font-size: 14px;
  line-height: 16px;
}

.btnWrap{
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-top: 10px;
}
