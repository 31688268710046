@import '../../../styles/variables';

.errorWrapper {
  position: absolute;
  z-index: 100;
  bottom: -60px;
}

.errorBlock {
  width: fit-content;
  background-color: $error;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-radius: 8px;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: white;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.errorBlock::after {
  content: '';
  position: absolute;
  left: 45%;
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid $error;
}

.visible {
  opacity: 0.9;
}

.small {
  padding: 7px;
}
