@import "../../../styles/variables";

.infoWrapper {
  padding: 20px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  background: $secondary-bg;
  border-radius: 8px;
}

.packageContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.packageTitleWrapper {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.packageTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.packageSubTitle {
  margin-top: 6px;
  font-size: 10px;
  color: $white-lightened;
}

.packageButton {
  margin-top: 24px;
  padding: 8px;
  background: transparent;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: $primary;
  cursor: pointer;
  border: 2px solid $primary-darkened;
  transition: all ease-in 0.3s;

  &:hover {
    color: #ffffff;
    border: 2px solid $primary;
  }
}

@media (max-width: $tabletMediumWidth) {
  .packageTitleWrapper {
    margin-left: 24px;
  }
}
