@import '../../styles/variables';

.main{
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $white;
  padding: 50px;
}

@media (max-width: $maxMobileWidth) {
  .main{
    padding: 25px;
  }
}