@import "../../../styles/variables";

.container {
  padding: 44px 12px 0 12px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  //height: fit-content;
  height: 100%;
  padding-bottom: 20px;
  overflow: visible;
  overflow-y: auto;
}

.widthWide {
  flex-basis: 280px
}

.widthShort {
  width: 80px
}

.shortMenu {
  .linksTitle {
    width: 0;
    overflow: hidden;
    margin: 0;
    opacity: 0;
  }

  .logo {
    visibility: hidden;
    margin-left: 0;
    width: 0;
  }

  .navItem {
    width: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .hamButton {
    height: 24px !important;
    width: 24px !important;
    margin-left: 9px;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
}

.hamButton {
  margin-left: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.logo {
  width: 71px;
  height: 72px;
  margin-left: 45px;
  cursor: pointer;
}

.navbar {
  margin-top: 58px;
  display: flex;
  flex-direction: column;
}

.navItem {
  margin-bottom: 24px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 220px;
  height: 48px;
  border-radius: 8px;
  transition: width 0.3s;
}

.navItem2 {
  margin-bottom: 12px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 196px;
  height: 48px;
  border-radius: 8px;
  transition: width 0.3s;
}

.navItemActive {
  background: rgba(77, 171, 245, 0.06);

  p {
    color: #fff;
    font-weight: 800;
  }

  svg path {
    fill: #fff;
  }
}

.navItemDisabled {
  margin-bottom: 24px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 196px;
  height: 48px;
  border-radius: 8px;
  transition: width 0.3s;

  p {
    color: rgba(168, 168, 168, 0.64);
    font-weight: 800;
  }

  svg path {
    fill: rgba(168, 168, 168, 0.64);
  }

  .link {
    display: flex;
    align-items: center;
    cursor: default;
  }
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.linksIcon {
  width: 24px;
  height: 24px;
  background: #c4c4c4;
  border-radius: 20px;
}

.linksTitle {
  margin-left: 27px;
  font-family: Playfair, sans-serif;
  font-style: normal;
  font-size: 15px;
  color: #fff;
  transition: width 0.3s;
  transition: opacity 0.5s 0.3s;
}

.referralBonusValue {
  margin-left: 27px;
  font-family: Playfair, sans-serif;
  font-style: normal;
  font-size: 15px;
  color: #FFAB52;
  transition: width 0.3s;
  transition: opacity 0.5s 0.3s;
}

.img {
  margin-left: 40px;
}

.containerMobile {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 12px 12px;
  margin: 0 15px;
  height: 96px;
  background: #012935;
  border-radius: 0 0 16px 16px;
  z-index: 20;
  position: fixed;
  width: calc(100% - 30px);
}

.buttonMobile {
  border: none;
  background: rgba(77, 171, 245, 0.06);
  border-radius: 0 0 8px 8px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  padding: 12px;
}

.activeButton {
  background: #feaf3c;

  svg path {
    fill: #192a4e;
  }
}

.logoButton {
  background: transparent;
  border: none;
  margin-top: 20px;
  width: 75px;
  height: 75px;
}

.title {
  //margin-bottom: 30px;
  color: #FFFFFF;
}

.collapse {
	padding: 136px 12px 0 12px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #012e3b;
	z-index: 10;
	overflow: auto;
}

@media (max-width: $tabletMediumWidth) {
  .container {
    display: none;
  }
  .containerMobile {
    display: flex;
  }
  .navbar {
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  .navItem {
    margin-bottom: 24px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-radius: 0px 16px 16px 0px;
    border-radius: 8px;
    background: transparent;
    border: none;
  }
  .navItem2 {
    margin-bottom: 12px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    border-radius: 0px 16px 16px 0px;
    border-radius: 8px;
    background: transparent;
    border: none;
  }
  .img {
    margin-left: 150px;
  }
  .navItemActive {
    background: rgba(77, 171, 245, 0.06);
    p {
      color: #fff;
      font-weight: 800;
    }
    svg path {
      fill: #fff;
    }
  }
  .navItemDisabled {
    p {
      color: rgba(168, 168, 168, 0.64);
      font-weight: 800;
    }
    svg path {
      fill: rgba(168, 168, 168, 0.64);
    }
  }
  .navItemTelegram {
    margin-top: 0;
  }
}

@media (max-width: $tabletMediumWidth) {
	.navbar {
		margin-top: 0px;
	}
}

@media (max-width: $desktopMinWidth) {
  .navbar {
    margin-bottom: 50px;
  }
}

